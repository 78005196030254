<template>
    <ui-create
        name="adverts"
        :title="title"
        :load="load"
    >
        <template #body >
            <v-form ref="form" >
                <v-row>
                    <v-col
                        cols="12"
                        sm="10"
                    >
                        <v-text-field
                            label="Nome"
                            v-model="form.nome"
                            counter
                            maxlength="128"
                            :rules="[
                                form_nome_rules.required, form_nome_rules.counter,
                                    form_nome_rules.min
                            ]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="2"
                    >
                        <v-checkbox
                            label="Ativo?"
                            v-model="form.ativo"
                            true-value="s"
                            false-value="n"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="form.inicio"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    label="Data Inicial"
                                    v-model="form.inicio"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.inicio"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                >
                                    Cancela
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(form.inicio)"
                                >
                                    Ok
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <v-dialog
                            ref="dialogFim"
                            v-model="modalFim"
                            :return-value.sync="form.fim"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    label="Data Final"
                                    v-model="form.fim"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.fim"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="modalFim = false"
                                >
                                    Cancela
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialogFim.save(form.fim)"
                                >
                                    Ok
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwadvertsCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            modal: false,
            modalFim: false,
            form: {
                nome: '',
                inicio: null,
                fim: null,
                ativo: null,
                nome_arquivo: null
            },
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            alertSuccess: {
                show: false,
                type: 'indigo',
                text: ''
            },

            form_nome_rules: {
                required: value => !!value || 'Nome é obrigatório.',
                counter: value => value.length <= 128 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_inicio_rules: {
                required: value => !!value || 'Insira a data de início.'
            },

            form_fim_rules: {
                required: value => !!value || 'Insira a data do fim.'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar propaganda: ${this.id}` : 'Cadastrar Propaganda'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            // if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            // }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/adverts/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-adverts', 'Cadastro atualizado!')
                    this.getData()
                })
                .catch(() => {
                    this.$root.$emit('open-snackbar-on-adverts', 'Erro interno ao atualizar a empresa!')
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/adverts/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-adverts', 'Empresa cadastrada com sucesso!')
                    this.clearForm()
                })
                .catch(() => {
                    this.$root.$emit('open-snackbar-on-adverts', 'Erro interno ao gravar a empresa!')
                })
                .finally(() => {
                    this.overlay = false
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/adverts/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-adverts', error.message)
                })
                .finally(() => {
                    this.overlay = false
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.$root.$on('save-changes-on-adverts', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>