var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-create',{attrs:{"name":"adverts","title":_vm.title,"load":_vm.load},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-text-field',{attrs:{"label":"Nome","counter":"","maxlength":"128","rules":[
                            _vm.form_nome_rules.required, _vm.form_nome_rules.counter,
                                _vm.form_nome_rules.min
                        ]},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-checkbox',{attrs:{"label":"Ativo?","true-value":"s","false-value":"n"},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.form.inicio,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "inicio", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "inicio", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.inicio),callback:function ($$v) {_vm.$set(_vm.form, "inicio", $$v)},expression:"form.inicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.form.inicio),callback:function ($$v) {_vm.$set(_vm.form, "inicio", $$v)},expression:"form.inicio"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancela ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.form.inicio)}}},[_vm._v(" Ok ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialogFim",attrs:{"return-value":_vm.form.fim,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "fim", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "fim", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.form.fim),callback:function ($$v) {_vm.$set(_vm.form, "fim", $$v)},expression:"form.fim"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFim),callback:function ($$v) {_vm.modalFim=$$v},expression:"modalFim"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.form.fim),callback:function ($$v) {_vm.$set(_vm.form, "fim", $$v)},expression:"form.fim"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFim = false}}},[_vm._v(" Cancela ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogFim.save(_vm.form.fim)}}},[_vm._v(" Ok ")])],1)],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }